<template>
  <div>
    <CRow class="px-1 px-lg-3">
      <CCol sm="12">
        <CRow class="p-2 justify-content-between">
          <CCol sm="3" class="py-3">
            <h4 class="text-primary">Terminals 🏧</h4>
          </CCol>
          <div>
            <CLink
              to="/become-an-agent"
              class="btn btn-primary text-white"
              @click.prevent="openAddTerminalModal"
              v-if="userObj.agent.account_type === 'main'"
              >Add Terminal</CLink
            >
          </div>
        </CRow>
        <div class="table-ressponsive">
          <v-table :data="terminals" class="table col-12 center mt-3">
            <thead slot="head">
              <tr>
                <th scope="col"></th>
                <th scope="col">Terminal Name</th>
                <th scope="col">Email</th>
                <th scope="col">Wallet Balance</th>
                <th scope="col">Commission Balance</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody slot="body" slot-scope="{ displayData }">
              <tr
                v-for="item in displayData"
                :key="item.id"
                class="pointer"
                @click="showMore(item)"
              >
                <td>
                  <CImg
                    :src="item.image"
                    width="50px"
                    height="50px"
                    shape="rounded"
                  />
                </td>
                <td scope="row">{{ item.name }}</td>
                <td>{{ item.email }}</td>
                <td>{{ item.customer_balance }}</td>
                <td>{{ item.commission_balance }}</td>
                <td>{{ item.created_at }}</td>
              </tr>
            </tbody>
          </v-table>
        </div>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import ApiService from "../services/api.service";
import { mapGetters } from "vuex";
// import Pagination from "@/components/CPagination.vue";
export default {
  name: "Terminals",
  components: {
    // Pagination,
  },
  data() {
    return {
      terminalLoading: false,
      terminals: [],
    };
  },
  computed: {
    ...mapGetters(["userObj"]),
  },
  methods: {
    async getTerminals() {
      this.terminalLoading = true;
      try {
        const response = await ApiService.get("partners/terminals");
        // console.log(response);
        this.terminals = response.data.data;
        console.log(this.terminals);
        this.terminalLoading = false;
      } catch (err) {
        console.log(err.response);
        this.terminalLoading = false;
      }
    },
    openAddTerminalModal() {
      console.log("Hello");
    },
  },
  mounted() {
    this.getTerminals();
  },
};
</script>

<style scoped></style>
